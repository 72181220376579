import React from 'react'
import { css } from 'linaria'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import Subheading from 'components/TextElements/Subheading'

import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'

const technologies = css`
  text-align: center;
  margin-top: ${rhythm(3 / 2)};

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${rhythm(3)};
  }
`

const logosContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .gatsby-image-wrapper {
    margin: 0 ${rhythm(3 / 2)} ${rhythm(1)};
    max-width: 140px;
    width: 100%;

    @media (max-width: ${breakpoints.md}) {
      margin: 0 -0.1rem ${rhythm(1)};
      transform: scale(0.75);
    }
  }
`

const List = ({ logos, alts, heading }) => (
  <div className={technologies}>
    <Subheading>{heading}</Subheading>
    <div className={logosContainer}>
      {logos &&
        logos.map((logo, i) => (
          <Img
            key={logo.id}
            fluid={logo.childImageSharp.fluid}
            style={{ maxWidth: logo.childImageSharp.fluid.presentationWidth }}
            alt={alts[i]}
          />
        ))}
    </div>
  </div>
)

const TechnologiesList = () => {
  const data = useStaticQuery(graphql`
    query Technologies {
      contentful: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "contentful" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 160, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      storyblok: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "storyblok" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 160, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      sanity: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "sanity" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 110, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      forestry: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "forestry" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 160, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      dato: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "dato" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 120, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      gatsby: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "gatsby" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 160, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      nextjs: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "nextjs" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 120, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      hugo: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "hugo" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 150, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      react: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "react" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 60, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      nodejs: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "nodejs" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 120, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      netlify: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "netlify" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 130, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      now: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "now-black" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 50, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      firebase: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "firebase" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 150, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
      aws: file(
        relativePath: { regex: "/img/technologies/" }
        name: { eq: "aws" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 70, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  `)

  return (
    <>
      <List
        logos={[
          data.contentful,
          data.storyblok,
          data.sanity,
          data.forestry,
          data.dato
        ]}
        alts={['Contentful', 'Storyblok', 'Sanity', 'Forestry', 'Dato']}
        heading="Data Sources"
      />
      <List
        logos={[data.gatsby, data.nextjs, data.hugo, data.react, data.nodejs]}
        alts={['Contentful', 'Storyblok', 'Sanity', 'Forestry', 'Dato']}
        heading="Build"
      />
      <List
        logos={[data.netlify, data.now, data.firebase, data.aws]}
        alts={['Netlify', 'Storyblok', 'Sanity', 'Forestry', 'Dato']}
        heading="Deploy & CDN"
      />
    </>
  )
}

export default TechnologiesList
