import React from 'react'
import { css } from 'linaria'
import { Link } from 'gatsby'

import breakpoints from '../../utils/tokens/breakpoints'
import { ReactComponent as SmallLogo } from '../../assets/svg/logo-small-white.svg'
import { ReactComponent as ArrowUp } from '../../assets/svg/icons/arrow-up.svg'
import rhythm from '../../utils/rhythm'
import { colors, transition } from '../../utils/theme'
import hexToRgb from '../../utils/hexToRgb'

import CTA from './CTA'
import SocialMedia from './SocialMedia'

const footer = css`
  @media (min-width: ${breakpoints.md}) {
    padding-left: 4vw;
    padding-right: 4vw;
    margin: 0 auto ${rhythm(2)};
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding: 0;
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
  }
`

const container = css`
  background: ${colors.background.dark};
  color: ${colors.text.white};
  padding: ${rhythm(2)} 8vw ${rhythm(1)};
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    border-radius: 16px;
    padding-top: ${rhythm(3)};
    padding-left: ${rhythm(3)};
    padding-right: ${rhythm(3)};
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding-top: ${rhythm(3)};
    padding-left: ${rhythm(5)};
    padding-right: ${rhythm(5)};
  }
`

const links = css`
  display: grid;
  margin-bottom: ${rhythm(1)};
  row-gap: ${rhythm(3)};

  ${
    '' /* @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 4fr;
    margin-bottom: ${rhythm(2)};
  } */
  } @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 2fr 7fr;
    margin-bottom: ${rhythm(2)};
  }

  @media (min-width: ${breakpoints.xl}) {
    grid-template-columns: 2fr 5fr;
  }

  @media (min-width: ${breakpoints.xxl}) {
    grid-template-columns: 2fr 4fr;
  }

  ul {
    list-style: none;
    font-size: 0.875rem;

    li {
      margin-bottom: ${rhythm(1)};
    }

    a {
      color: ${colors.text.white};
      text-decoration: none;
      opacity: 0.5;
      transition: all ${transition.speed.fast} ${transition.curve.accelerate};

      &:hover {
        opacity: 1;
        transition-timing-function: ${transition.curve.decelerate};
      }
    }
  }
`

const logo = css`
  max-width: 2rem;
  height: auto;
`

const copyright = css`
  font-size: 0.8125rem;
`

const nav = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${rhythm(1)};
  row-gap: ${rhythm(3 / 2)};

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: auto auto auto;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: auto auto auto 140px;
  }

  ul {
    margin: 0;
  }
`

const goToTop = css`
  background: ${colors.accent.purple};
  border-radius: 100%;
  width: 3.5rem;
  height: 3.5rem;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${rhythm(3)};
  top: 0;
  transform: translateY(-50%);
  transition: box-shadow ${transition.speed.l} ${transition.curve.scaleIn};
  will-change: box-shadow;

  svg {
    will-change: transform;
    transform: scale(0.95);
    transition: transform ${transition.speed.l} ${transition.curve.scaleUp};
  }

  &:hover {
    box-shadow: 0 9px 40px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.65);

    svg {
      transform: translateY(-2px) scale(1);
    }
  }

  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }

  @media (min-width: ${breakpoints.xxl}) {
    right: ${rhythm(5)};
  }
`

const GoTop = () => {
  const scrollToTop = e => {
    e.preventDefault()
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <a href="#" title="Scroll to top" className={goToTop} onClick={scrollToTop}>
      <ArrowUp />
    </a>
  )
}

const Footer = ({ children, preLinksComponent = null }) => (
  <footer className={footer}>
    <div className={container}>
      <GoTop />
      {preLinksComponent}
      <section className={links}>
        <Link to="/" aria-label="Bejamas Home">
          <SmallLogo className={logo} viewBox="0 0 317.758 283.971" />
        </Link>
        <nav className={nav}>
          <div>
            <p>Company</p>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about/">About</Link>
              </li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
              <li>
                <Link to="/privacy-policy/">Privacy</Link>
              </li>
              <li>
                <Link to="/cookie-policy/">Cookies</Link>
              </li>
            </ul>
          </div>
          <div>
            <p>Services</p>
            <ul>
              <li>
                <Link to="/services/jamstack-website/">JAMstack Websites</Link>
              </li>
              <li>
                <Link to="/services/progressive-web-app/">
                  Progressive Web Apps
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <p>Get in touch</p>
            <ul>
              <li>
                <Link to="/estimate-project/">Estimate project</Link>
              </li>
              <li>
                <Link to="/contact/">Contact</Link>
              </li>
            </ul>
          </div>

          <SocialMedia />
        </nav>
      </section>
      <p className={copyright}>© Bejamas.io - All rights reserved.</p>
    </div>
  </footer>
)

export default Footer
