const theme = {
  // Colors
  colors: {
    background: {
      dark: '#0a0012',
      lightGray: '#f5f9fb',
      white: '#fff'
    },
    text: {
      darkGray: '#3c4856',
      lightGray: '#3c4856c2',
      white: '#fff'
    },
    accent: {
      // purple: '#f94062',
      // purple: '#D83856',
      purple: '#da3654',
      violet: '#2e0276',
      dark: '#121616',
      orange: '#fc9b1f',
      turquoise: '#02ddd5'
    }
  },
  fonts: {
    heading: `Recoleta, Georgia, serif`,
    body: `Poppins, Helvetica, sans-serif`
  },
  transition: {
    curve: {
      // marvel
      // No curve. Good for color/opacity fades
      linear: `linear`,
      // Slight curve. Good for subtle transitions
      ease: `ease`,
      // Starts fast and decelerates as it progresses
      scaleIn: `cubic-bezier(0, 0.89, 0.44, 1)`,
      // Starts slow and accelerates as it progresses
      scaleOut: `cubic-bezier(0.165, 0.84, 0.44, 1)`,
      // Springy feel. Good for drawing focus
      scaleUp: `cubic-bezier(0.68, -0.55, 0.265, 1.55)`,

      default: `cubic-bezier(0.4, 0, 0.2, 1)`,
      // scaleUp: `cubic-bezier(0.68, -0.55, 0.265, 1.55)`,
      decelerate: `cubic-bezier(0.1 , 0.9 , 0.2 , 1)`,
      accelerate: `cubic-bezier(0.7 , 0 , 1 , 0.5)`
    },
    speed: {
      s: `80ms`,
      m: `150ms`,
      l: `300ms`,
      xl: `500ms`,
      xxl: `700ms`,
      default: `250ms`,
      fast: `150ms`,
      slow: `500ms`
    }
  },
  // GRID
  space: [0, 5, 10, 15, 24],
  breakpoints: ['48em', '62em', '75rem', '82.5rem']
}

export const colors = theme.colors
export const fonts = theme.fonts
export const transition = theme.transition

export default theme
