import React from 'react'
import { css } from 'linaria'
import breakpoints from '../../utils/tokens/breakpoints'

const page = css`
  margin-left: auto;
  margin-right: auto;
  padding-left: 8vw;
  padding-right: 8vw;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 4vw;
    padding-right: 4vw;
    overflow: hidden;
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding-left: 0;
    padding-right: 0;
    max-width: 87.5rem;
    overflow: visible;
  }
`

const Main = ({ children }) => <main className={page}>{children}</main>

export default Main

export const globals = css`
  :global() {
    @font-face {
      font-family: 'Recoleta';
      font-style: normal;
      font-display: swap;
      font-weight: bold;
      src: url('/fonts/recoleta/subset-Recoleta-SemiBold.woff2') format('woff2'),
        url('/fonts/recoleta/subset-Recoleta-SemiBold.woff') format('woff');
    }
    /* poppins-400normal - latin */
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: url('/fonts/poppins/poppins-latin-500.woff2') format('woff2'),
        url('/fonts/poppins/poppins-latin-500.woff') format('woff');
    }

    ${'' /* *:focus {
      outline: 0;
    } */}
  }
`
