import React from 'react'
import { Link, navigate } from 'gatsby'
import { css } from 'linaria'
import Img from 'gatsby-image'
import delve from 'dlv'

import ButtonSecondary from '../Button/Secondary'
import { colors, transition } from '../../utils/theme'
import rhythm from '../../utils/rhythm'

import cx from '../../utils/cx'
import hexToRgb from '../../utils/hexToRgb'
import breakpoints from '../../utils/tokens/breakpoints'

const featuredClass = css`
  @media (min-width: ${breakpoints.xl}) {
    margin-right: ${rhythm(3)};
  }
`

const article = css`
  cursor: pointer;
  position: relative;
  margin-bottom: auto;
  transition: transform ${transition.speed.l} ${transition.curve.scaleOut};

  .gatsby-image-wrapper {
    border-radius: 8px;
    overflow: hidden;
  }

  &::before {
    content: '';
    width: calc(100% + ${rhythm(2)});
    height: calc(100% + ${rhythm(2)});
    background: ${colors.background.lightGray};
    background: #fff;
    position: absolute;
    left: -${rhythm(1)};
    top: -${rhythm(1)};
    display: block;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    border-radius: 8px;
    transition: all ${transition.speed.l} ${transition.curve.scaleOut};
    box-shadow: 0 9px 60px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.15);
  }

  h3 {
    margin-top: 0;

    a {
      transition: color ${transition.speed.m} ${transition.curve.scaleIn};
    }
  }

  &:hover {
    transform: translateY(-${rhythm(1 / 8)});

    &::before {
      opacity: 1;
      visibility: visible;
    }

    h3 a {
      color: ${colors.accent.purple};
    }
  }

  p {
    color: ${colors.text.lightGray};
    margin-bottom: 0;
  }
`

const coverStyles = css`
  grid-area: cover;
  margin-bottom: ${rhythm(1)};
  align-self: flex-start;
  border-radius: 8px;
  overflow: hidden;
`

const PostTile = ({
  post,
  featured = false,
  articleClassName,
  coverPosition = 'top',
  seeMore = true,
  coverClassName,
  locationState = {}
}) => (
  <article
    className={cx({
      [article]: true,
      [featuredClass]: featured,
      [articleClassName]: Boolean(articleClassName)
    })}
    onClick={() => navigate(post.fields.slug, { state: locationState })}
  >
    {delve(post, 'frontmatter.image.childImageSharp.fluid') && (
      <Img
        fluid={post.frontmatter.image.childImageSharp.fluid}
        className={cx({
          [coverStyles]: true,
          [coverClassName]: Boolean(coverClassName)
        })}
      />
    )}
    <div
      className={css`
        ${{ gridArea: 'content', position: 'relative' }};
      `}
    >
      <h3
        className={css`
          ${{ marginTop: rhythm(1) }};
        `}
      >
        <Link
          to={post.fields.slug}
          className={css`
            ${{ color: colors.text.darkGray, textDecoration: 'none' }};
          `}
          state={{
            ...locationState
          }}
        >
          {post.frontmatter.title}
        </Link>
      </h3>
      {post.frontmatter.excerpt && <p>{post.frontmatter.excerpt}</p>}
      {seeMore && (
        <ButtonSecondary
          to={post.fields.slug}
          className={css`
            ${{ marginTop: rhythm(1) }};
          `}
        >
          > See more
        </ButtonSecondary>
      )}
    </div>
  </article>
)

export default PostTile
