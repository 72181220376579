import React, { useState, useEffect, useMemo, useRef } from 'react'
import { IdleQueue } from 'idlize/IdleQueue.mjs'

import Header from '../Header'
import Main from '../Main'
import Footer from '../Footer'
import Seo from '../Seo'
// import Placeholder from '../Placeholder'

import useWindowScroll from '../../utils/hooks/useWindowScroll'

const isSSR = typeof window === 'undefined'

const Layout = ({
  location,
  children,
  title,
  addSuffixToTitle = false,
  description,
  image,
  preHeaderComponent,
  showHeader = true,
  customHeader,
  postHeaderComponent,
  preLinksComponent,
  showCtaInHeader = false,
  jsonLD
}) => {
  const [renderChildren, startRenderChildren] = useState(isSSR)
  const [renderFooter, startRenderFooter] = useState(isSSR)

  let queue = useRef(null)

  const checkIfRenderImmediately = ({ key, hash }) => {
    if (isSSR || hash) {
      return true
    }

    const scrollKey = `@@scroll|${key}`
    const scrollPosition = window.sessionStorage.getItem(scrollKey)

    if (scrollPosition) {
      let [, y] = JSON.parse(scrollPosition)

      if (y > 600) {
        return true
      }
    }

    return false
  }

  const { key, hash } = location

  const shouldRenderImmediately = useMemo(
    () => checkIfRenderImmediately({ key, hash }),
    [key, hash]
  )

  // this one line improves Max Potential First Input Delay 2x.
  // i have no idea why, but it does.
  const [hack, setHack] = useState(shouldRenderImmediately ? 0 : window.scrollY)

  useEffect(() => {
    queue.current = new IdleQueue({
      defaultMinTaskTime: 40, // Only run if there's lots of time left.
      ensureTasksRun: true
    })

    if (!renderChildren && !shouldRenderImmediately) {
      queue.current.pushTask(() => startRenderChildren(true))
    }

    if (!renderFooter && !shouldRenderImmediately) {
      queue.current.pushTask(() => startRenderFooter(true))
    }

    return () => {
      queue.current.clearPendingTasks()
    }
  }, [queue])

  useWindowScroll(
    ({ y }) => {
      if (!queue.current || renderChildren || shouldRenderImmediately) {
        return
      }

      if (y > 300 && queue.current.hasPendingTasks()) {
        queue.current.runTasksImmediately()
      }
    },
    [queue]
  )

  // we have to render children and footer immedietly if:
  // 1. scroll position y > 600
  // 2. location hash exists
  const renderChild = (child, i) => {
    return shouldRenderImmediately || renderChildren || i < 2 ? child : null
  }

  return (
    <>
      {showHeader &&
        (customHeader || (
          <Header
            preHeaderComponent={preHeaderComponent}
            postHeaderComponent={postHeaderComponent}
            location={location}
            showCtaInHeader={showCtaInHeader}
            pageTitle={title}
          />
        ))}
      <Main>{React.Children.map(children, renderChild)}</Main>
      {(renderFooter || shouldRenderImmediately) && (
        <Footer preLinksComponent={preLinksComponent} />
      )}
      <Seo
        pathname={location.pathname}
        pageTitle={title}
        pageDescription={description}
        pageImage={image}
        addSuffixToTitle={addSuffixToTitle}
        jsonLD={jsonLD}
      />
    </>
  )
}

export default Layout
