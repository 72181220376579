import React, { useEffect, useState } from 'react'
import { css } from 'linaria'
import { Link } from 'gatsby'
import Button from 'components/Button'

import { colors, transition } from '../../utils/theme'
import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'
import cx from '../../utils/cx'

import { ReactComponent as Logo } from 'assets/svg/logo-black.svg'
import { ReactComponent as BurgerIcon } from 'assets/svg/icons/burger.svg'
import { ReactComponent as ArrowBottom } from '../../assets/svg/icons/arrow-bottom.svg'

import useWindowScroll from '../../utils/hooks/useWindowScroll'

const header = css`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;

  @supports (backdrop-filter: blur(20px)) {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(16px);
  }
`

const headerOpen = css`
  height: 100vh;

  @media (max-width: ${breakpoints.lg}) {
    overflow: auto;
  }
`

const container = css`
  display: grid;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'logo cta burger'
    'nav nav nav';

  @media (max-width: ${breakpoints.lg}) {
    padding: ${rhythm(1 / 2)} 8vw;
    grid-template-rows: 60px auto;
    z-index: 8;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: ${rhythm(1 / 2)} 4vw;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 'logo nav cta';
    padding: 0 4vw;
  }

  @media (min-width: ${breakpoints.xxl}) {
    max-width: 87.5rem;
    padding: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    grid-template-rows: 44px auto;
  }

  .cta {
    transition: all ${transition.speed.xl} ${transition.curve.ease};
  }
`

const navUl = css`
  justify-content: center;
  list-style: none;
  margin: 0;

  li {
    margin: 0;

    &:hover {
      > ul {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

  > li {
    padding: 0 ${rhythm(1 / 4)};
  }

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
  }
`

const link = css`
  text-decoration: none;
  border-top: 2px solid transparent;
  color: ${colors.accent.dark};
  display: flex;
  align-items: center;
  padding: ${rhythm(3 / 2)} ${rhythm(1)};

  &:hover {
    color: ${colors.accent.purple};
  }
`

const activeLink = css`
  color: ${colors.accent.purple};
  @media (min-width: ${breakpoints.lg}) {
    border-top: 2px solid ${colors.accent.purple};
  }
`

const logo = css`
  max-width: 6.25rem;
  height: 30px;
  margin-top: 0.5rem;

  @media (min-width: ${breakpoints.md}) {
    max-width: 11.5rem;
    max-width: ${rhythm(6)};
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: ${rhythm(7)};
  }
`

const logoArea = css`
  grid-area: logo;
  z-index: 8;
`

const nav = css`
  grid-area: nav;
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    transition: all ${transition.speed.xl} ${transition.curve.ease};
    display: block;
  }
`

const burger = css`
  box-shadow: none;
  border: 0;
  background: transparent;
  margin: 0.5rem 0 0 ${rhythm(1 / 2)};
  padding: 0;
  grid-area: burger;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

const desktopEstimate = css`
  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`

const mobileTalk = css`
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

const openedNav = css`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  font-size: 1rem;
  text-align: center;
  margin-top: ${rhythm(1 / 2)};
  margin-bottom: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  > ul {
    padding-top: ${rhythm(1 / 3)};
    width: 100%;
  }

  li {
    padding: 0;
  }

  a {
    padding: ${rhythm(1 / 3)} 0;
  }

  @media (min-height: 500px) {
    font-size: 1.125rem;

    a {
      padding: ${rhythm(1 / 2)} 0;
    }
  }
`

const mobNavCta = css`
  margin: ${rhythm(1 / 2)} 0;
  width: 100%;

  p {
    font-size: 0.75rem;
  }

  a {
    width: 100%;
    font-size: 1.1875rem;
  }

  @media (min-height: 420px) {
    width: 84vw;
    position: fixed;
    left: 8vw;
    right: 8vw;
    bottom: 0;
    margin-bottom: ${rhythm(1)};
  }

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

const hamburger = css`
  grid-area: burger;
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0.5rem 0 0 ${rhythm(1 / 3)};
  padding: 0px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  outline: 0;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`

const hamburgerActive = css`
  > span > span {
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(45deg);
    background-color: #000;

    &::before {
      top: 0;
      transition: top 75ms ease, opacity 75ms ease 0.12s;
      opacity: 0;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 75ms ease,
        transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    }
  }
`

const hamburgerBox = css`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 24px;
`

const hamburgerInner = css`
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
  position: absolute;
  width: 28px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 3px;
  background-color: #000;
  top: 50%;
  display: block;
  margin-top: -2px;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 28px;
    height: 3px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 3px;
    background-color: #000;
  }

  &::before {
    transition: top 75ms ease 0.12s, opacity 75ms ease;
    top: -8px;
  }

  &::after {
    transition: bottom 75ms ease 0.12s,
      transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -8px;
  }
`

const submenu = css`
  background: ${colors.background.white};
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  margin: 0;
  list-style: none;
  box-shadow: 0 9px 60px 0 rgba(60, 72, 86, 0.16);
  padding: ${rhythm(2 / 3)} 0;
  transition: all ${transition.speed.l} ${transition.curve.scaleIn};
  transform: translateY(-4px);

  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }

  &:hover {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  a {
    text-decoration: none;
    color: ${colors.text.darkGray};
    padding: ${rhythm(1 / 3)} ${rhythm(5 / 4)};
    display: block;

    &:hover {
      color: ${colors.accent.purple};
    }
  }
`

const Header = ({
  preHeaderComponent = null,
  postHeaderComponent = null,
  showCtaInHeader,
  location,
  pageTitle
}) => {
  const [ctaButtonVisibility, setCtaVisibility] = useState(showCtaInHeader)
  const [mobileNavOpen, setOpen] = useState(false)
  const [submenuVisibility, openSubmenu] = useState(false)

  useWindowScroll(
    ({ y }) => {
      let windowHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      )

      if (showCtaInHeader) {
        return
      }

      if (y > windowHeight) {
        setCtaVisibility(true)
      } else {
        setCtaVisibility(false)
      }
    },
    [ctaButtonVisibility]
  )

  let navClass = cx({
    [nav]: true,
    [openedNav]: mobileNavOpen
  })

  let headerClass = cx({
    [header]: true,
    [headerOpen]: mobileNavOpen
  })

  let hamburgerStyles = cx({
    [hamburger]: true,
    [hamburgerActive]: mobileNavOpen
  })

  return (
    <header className={headerClass}>
      {preHeaderComponent && preHeaderComponent}
      <div className={container}>
        <div className={logoArea}>
          <Link to="/" aria-label="Bejamas Home">
            <Logo className={logo} viewBox="0 0 1823.23 288.662" />
          </Link>
        </div>
        <nav className={navClass} aria-label="Main Navigation">
          <ul className={navUl}>
            <li>
              <Link to="/" className={link} activeClassName={activeLink}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about/" className={link} activeClassName={activeLink}>
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services/"
                className={link}
                activeClassName={activeLink}
                partiallyActive
              >
                Services
                <ArrowBottom
                  className={css`
                    ${{
                      marginLeft: rhythm(1 / 2),
                      display: 'none',
                      [`@media (min-width: ${breakpoints.lg})`]: {
                        display: 'block'
                      }
                    }};
                  `}
                />
              </Link>

              <ul className={submenu}>
                <li>
                  <Link to="/services/jamstack-website/">
                    JAMstack websites
                  </Link>
                </li>
                <li>
                  <Link to="/services/progressive-web-app/">
                    Progressive Web Apps
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/blog/"
                className={link}
                activeClassName={activeLink}
                partiallyActive
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/contact/"
                className={link}
                activeClassName={activeLink}
              >
                Contact
              </Link>
            </li>
          </ul>
          <div className={mobNavCta}>
            <p>
              Get a <strong>free</strong> one-to-one consultation.
            </p>
            <Button
              to="/estimate-project/"
              state={{
                prevLocation: location.pathname
              }}
            >
              Book a call
            </Button>
          </div>
          {!mobileNavOpen && (
            <Button
              to="/estimate-project/"
              variant="small"
              className={mobileTalk}
              state={{
                prevLocation: location.pathname
              }}
            >
              Estimate Project
            </Button>
          )}
        </nav>

        <div
          className={
            'cta ' +
            css`
              ${{
                justifySelf: 'end',
                display: 'flex',
                alignItems: 'center'
              }};
            `
          }
        >
          {ctaButtonVisibility && !mobileNavOpen && (
            <Button
              to="/estimate-project/"
              variant="small"
              className={mobileTalk}
              state={{
                prevLocation: location.pathname
              }}
            >
              Let's Talk
            </Button>
          )}
          <Button
            to="/estimate-project/"
            className={desktopEstimate}
            state={{
              prevLocation: location.pathname
            }}
          >
            Get Free Advice
          </Button>
          <button
            className={hamburgerStyles}
            type="button"
            aria-label="Open Menu"
            onClick={() => setOpen(!mobileNavOpen)}
          >
            <span className={hamburgerBox}>
              <span className={hamburgerInner} />
            </span>
          </button>
          {/* <button className={burger} onClick={() => setOpen(!mobileNavOpen)}>
            {mobileNavOpen ? 'Close' : <BurgerIcon />}
          </button> */}
        </div>
      </div>
      {postHeaderComponent && postHeaderComponent}
    </header>
  )
}
export default Header
