import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import cx from '../../utils/cx'

import { colors } from '../../utils/theme'
import hexToRgb from '../../utils/hexToRgb'
import rhythm from '../../utils/rhythm'
import { transition } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'

const button = css`
  border-radius: 28px;
  background-color: ${colors.accent.purple};
  color: ${colors.background.white};
  padding: ${rhythm(2 / 3)} ${rhythm(4 / 3)};
  text-decoration: none;
  box-shadow: 0 9px 60px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.35);
  min-width: 9rem;
  display: inline-block;
  text-align: center;
  border: 0;
  box-shadow: 0 0 0 0 rgba(${hexToRgb(colors.accent.purple)}, 0);
  transition: box-shadow ${transition.speed.l} ${transition.curve.scaleIn},
    transform ${transition.speed.m} ${transition.curve.scaleIn};
  will-change: box-shadow, transform;
  cursor: pointer;

  &:hover,
  &:focus {
    transform: scale(1.03) translate3d(0, 0, 0);
    box-shadow: 0 9px 40px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.65);
    outline: 0;
  }

  &:active {
    transform: scale(0.975);
    box-shadow: 0 0 30px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.65);
  }
`

const small = css`
  padding: ${rhythm(2 / 5)} ${rhythm(2 / 3)};
  font-size: 0.875rem;
  min-width: 0;
`

const Button = ({
  children,
  state,
  className = null,
  variant = '',
  tag: Tag = Link,
  ...rest
}) => {
  let styles = cx({
    [button]: true,
    [small]: variant === 'small',
    [className]: className && className.length > 0
  })

  let locationState = { ...state }

  if (typeof window !== 'undefined') {
    locationState = {
      prevLocation: window.location.pathname
    }
  }

  return (
    <Tag className={styles} state={locationState} {...rest}>
      {children}
    </Tag>
  )
}

export default Button
